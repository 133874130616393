import React from "react";
import { SiRobotframework } from "react-icons/si"
import { displayValueForKey, messageResponse } from "../functions";


const TemporaryMsgs: React.FC<any> = ({ tempMsg, modelInfo }) => {


    return <>
        <div key={tempMsg.length+Math.random()} className="user msg w-full flex items-center justify-end ">
            <span
                className={`px-2 p-1 m-1 bg-[#0e25291b] rounded-lg text-gray-[300] font-md text-md flex flex-col items-start justify-end`}
            >

                {
                    modelInfo.inputType.map((input: any) => messageResponse(input.type, displayValueForKey(tempMsg, input.name)))
                }



            </span>
        </div>

        <div className="ai-msg w-full flex items-center justify-start ">
            <SiRobotframework className="text-sm text-[#3BB5FE]" />
            <Loader />
        </div>
    </>
}

export default TemporaryMsgs

const Loader = () => {

    return <span className="px-2 p-1 m-1 bg-gray-100 rounded-lg text-gray-700 font-semibold text-md">
        <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </span>
}